import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {user as authUser} from '@angular/fire/auth';

import {ClientService} from '@library/shared/_services/client.service';
import {environment} from '@env/environment';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';

import {User} from '@nxt/model-core';

@Injectable()
export class LaroConsumerClientGuard {
    constructor(
        private cSvc: ClientService,
        private fSvc: ConsumerFireService,
        private aSvc: ConsumerAccountService
    ) {

  }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise((resolve) => {
            this.cSvc.c$.next(environment.default_client);
            authUser(this.aSvc.auth)
                .subscribe(
                async (u: any) => {
                    this.cSvc.user = u?.uid ? new User(await this.fSvc.getDoc(`users/${u.uid}`).toPromise()) : null;
                    resolve(true);
                },
                async (err: any) => {
                    console.warn(err);
                    resolve(false);
                }
            );

        });
    }

}
