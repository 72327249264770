"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircraftModel = exports.AircraftTypes = exports.EAircraftTypes = exports.Aircraft = exports.AircraftWarnings = exports.AircraftErrors = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
var AircraftErrors;
(function (AircraftErrors) {
    AircraftErrors["NO_MODEL"] = "No closest model found.";
    AircraftErrors["NO_SEAT_COUNT"] = "No seat count value found.";
    AircraftErrors["NO_TAIL_NUMBER"] = "No tail number found.";
    AircraftErrors["NO_NAME"] = "No name found.";
})(AircraftErrors || (exports.AircraftErrors = AircraftErrors = {}));
var AircraftWarnings;
(function (AircraftWarnings) {
    AircraftWarnings["NO_AMENITIES"] = "No amenities found.";
    AircraftWarnings["NO_IMAGES"] = "No images added.";
})(AircraftWarnings || (exports.AircraftWarnings = AircraftWarnings = {}));
class Aircraft extends model_core_1.Base {
    constructor(data, olMap) {
        var _a, _b, _c;
        super('aircraft', 20);
        this.tail_number = '';
        this.call_sign = '';
        this.type = '';
        this.name = '';
        this.description = '';
        this.images = [];
        this.amenities = {};
        this.source = '';
        this.source_data = {};
        this.seats = null;
        this.accounts = [];
        this.account_ids = []; // TODO Change to a getter?
        this.latitude = null;
        this.longitude = null;
        this.errors = [];
        this.warnings = [];
        this.model = null;
        this.airport = null;
        this._m = ['tail_number', 'name', 'account_ids'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Aircraft);
        if (((_a = this.accounts) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.account_ids) === null || _b === void 0 ? void 0 : _b.length)) {
            this.account_ids = (_c = this.accounts) === null || _c === void 0 ? void 0 : _c.map(i => i.id);
        }
    }
    async loadAll(opts, stack, p) {
        opts = opts || { default_client_id: '' };
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        return super.loadAll(opts, stack, p);
    }
    buildAccountIDs() {
        var _a;
        this.account_ids = ((_a = this.accounts) === null || _a === void 0 ? void 0 : _a.length) ? this.accounts.map(i => i.id) : this.account_ids;
    }
    errorCheck(force) {
        var _a, _b, _c;
        this.errors = [];
        this.warnings = [];
        if (!this.tail_number) {
            this.errors.push(AircraftErrors.NO_TAIL_NUMBER);
        }
        if (this.active || force) {
            if (!this.name) {
                this.errors.push(AircraftErrors.NO_NAME);
            }
            if (!((_a = this.model) === null || _a === void 0 ? void 0 : _a.id)) {
                this.errors.push(AircraftErrors.NO_MODEL);
            }
            if (!this.seats) {
                this.errors.push(AircraftErrors.NO_SEAT_COUNT);
            }
            // Warnings don't prevent the aircraft from working in the system.
            if (!((_b = this.images) === null || _b === void 0 ? void 0 : _b.length)) {
                this.warnings.push(AircraftWarnings.NO_IMAGES);
            }
            if (!Object.keys(this.amenities || {}).length) {
                this.warnings.push(AircraftWarnings.NO_AMENITIES);
            }
        }
        if ((_c = this.errors) === null || _c === void 0 ? void 0 : _c.length) {
            this.active = false;
        }
    }
    toJSON() {
        this.buildAccountIDs();
        this.errorCheck();
        return super.toJSON();
    }
    toMinJSON(ignoreDocRef) {
        this.buildAccountIDs();
        return super.toMinJSON(ignoreDocRef);
    }
}
exports.Aircraft = Aircraft;
var EAircraftTypes;
(function (EAircraftTypes) {
    EAircraftTypes["NONE"] = "";
    EAircraftTypes["LIGHT"] = "l";
    EAircraftTypes["LIGHT_JET"] = "lj";
    EAircraftTypes["VERY_LIGHT_JET"] = "vlj";
    EAircraftTypes["MIDSIZE"] = "m";
    EAircraftTypes["SUPERMID"] = "sm";
    EAircraftTypes["HEAVY"] = "h";
    EAircraftTypes["TURBO_PROP"] = "tp";
    EAircraftTypes["SINGLE_ENGINE"] = "se";
})(EAircraftTypes || (exports.EAircraftTypes = EAircraftTypes = {}));
exports.AircraftTypes = {
    'None Selected': EAircraftTypes.NONE,
    // 'Light': EAircraftTypes.LIGHT,
    'Light Jet': EAircraftTypes.LIGHT_JET,
    'Very Light Jet': EAircraftTypes.VERY_LIGHT_JET,
    'Midsize Jet': EAircraftTypes.MIDSIZE,
    'Supermid Jet': EAircraftTypes.SUPERMID,
    'Heavy Jet': EAircraftTypes.HEAVY,
    'Turbo Prop': EAircraftTypes.TURBO_PROP,
    'Single Engine': EAircraftTypes.SINGLE_ENGINE
};
// export const AVIAPAGES_AIRCRAFT_TYPE_MAP = {
//     "Super midsize": EAircraftTypes.SUPERMID,
//     "Heavy": EAircraftTypes.HEAVY,
//     "Midsize": EAircraftTypes.MIDSIZE,
//     "Light": EAircraftTypes.LIGHT
// }
class AircraftModel extends model_core_1.Base {
    get amenityList() {
        if (!this._amenityList) {
            this._amenityList = Object.keys(this.amenities);
        }
        return this._amenityList;
    }
    constructor(data, olMap) {
        super('aircraft_models', 19);
        this.name = '';
        this.make = '';
        this.model = '';
        this.icao = '';
        this.type = EAircraftTypes.NONE;
        this.height = '';
        this.width = '';
        this.length = '';
        this.rangeNM = null;
        this.speedMPH = null;
        this.seats = null;
        this.bags = null;
        this.description = '';
        this.metadata = {};
        this.images = [];
        this.amenities = {};
        this.alternative = null;
        this._m = ['name'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, AircraftModel);
    }
}
exports.AircraftModel = AircraftModel;
index_1.olm.aircraft = (ref, map) => {
    return new Aircraft(ref, map);
};
index_1.olm.aircraft_models = (ref, map) => {
    return new AircraftModel(ref, map);
};
