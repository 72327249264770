export const environment = {
    production: false,
    appName: 'trips.levo.aero',
    type: 'dev',
    pageServiceSiteId: 'trips',
    firebase: {
        apiKey: 'AIzaSyCAP_G1k8wgo2EiLgrsStpfiL-FxAedS_E',
        authDomain: 'dev.trips.aero',
        projectId: 'laro-dev',
        storageBucket: 'laro-dev.appspot.com',
        messagingSenderId: '1075833134169',
        appId: '1:1075833134169:web:cd637c55bea05b8f76a548',
        measurementId: 'G-MY0XTKZ7YM'
    },
    nxtProjectId: 'laro-dev',
    url: 'https://dev.levo.aero',
    public_url: 'https://dev.trips.aero',
    fnUrl: 'https://us-central1-laro-dev.cloudfunctions.net/api',
    storageUrl: 'https://firebasestorage.googleapis.com/v0/b/laro-dev.appspot.com/o/',
    push_public_key: 'BLHVMnQU14SkaUOVWNjBlWIHCaYcc_S0Cua4djVpm8eVCg5yQbq7afpSwT-rmgcgc40Gsd5kH8NsFPtdZT1mhj4',
    default_client: {
        theme: {
            dark: '#35baaa',
            light: '#efefef',
            accent: '#445674',
            icon: '/assets/icons/48.png',
            logo_light: ''
        },
        id: 'uwgw8vegtw6rvabhpoa7v4',
        name_key: 'LARO',
        phone: '',
        public_facing_url: 'https://dev.trips.aero',
        settings: [
            {id: 'email', name: 'Email'},
            {id: 'chat', name: 'Chat'}
        ]
    },
    required_settings: [
        {id: 'email', name: 'Email'}
    ],
    stripe: 'pk_test_XF2MYh3Ks9puBOCCMzJp4gh7',
    paypal: {client_id: ''},
    algolia: {
        appId: 'OSZRSPVEKD',
        searchKey: 'e883f6c4a6782f1b532831e576d3487a'
    }
};
