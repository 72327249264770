import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {ConsumerAppModule} from './consumer/consumer.app.module';
// @ts-ignore
import {environment} from '@env/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(ConsumerAppModule).catch(err => console.error(err));
