import {ErrorHandler, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {provideFirebaseApp, initializeApp, getApp} from '@angular/fire/app';
import {provideAnalytics, getAnalytics, ScreenTrackingService} from '@angular/fire/analytics';
import {ServiceWorkerModule} from '@angular/service-worker';

import {ConsumerAppComponent} from './consumer.app.component';

import {PageService} from '@library/shared/_services/page.service';

import {AlertDialog} from '@library/shared/alert/alert.dialog';

import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {ProgressComponent} from '@library/shared/progress/progress.component';
import {ModalDialog} from '@library/shared/modal/modal.dialog';
import {ClientGuard} from '@library/shared/_services/client.guard';
import {ThemeService} from '@library/shared/_services/theme.service';
import {NotificationDialog} from '@library/shared/alert/notification.dialog';
import {ClientService} from '@library/shared/_services/client.service';
import {LaroObjectLoaderService} from '@library/laro/_services/laro.object.loader.service';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {AnalyticsService} from '@library/shared/_services/analytics.service';
import {GlobalErrorHandler} from '@library/shared/_services/global-error-handler';


// @ts-ignore
import {environment} from '@env/environment';
import {sharedLazyComponents} from "../../../_library/shared.lazy.components";
import {laroConsumerLazyComponents} from "./_components/laro-consumer.lazy.components";
import {FlexPage} from '@library/shared/flex/flex.page';
import {laroOperatorLazyComponents} from '../operator/laro-operator.lazy.components';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {LaroConsumerClientGuard} from './_services/laro-consumer-client.guard';
import {CartService} from '@library/shared/_services/cart.service';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'checkout',
                loadChildren: () => import(`./checkout/laro-consumer-checkout.module`).then(m => m.LaroConsumerCheckoutModule),
                canActivate: [ClientGuard,LaroConsumerClientGuard]
            },
            {
                path: 'login',
                loadChildren: () => import(`./login/laro-consumer-login.module`).then(m => m.LaroConsumerLoginModule),
                canActivate: [ClientGuard]
            },
            {
                path: ':name_key/book',
                loadChildren: () => import('./book/book.module').then(m => m.BookModule),
                canActivate: [ClientGuard],
                data: {path: 'book'}
            },{
                path: ':name_key/iframe',
                loadChildren: () => import('./consumer.iframe.module').then(m => m.ConsumerIframeModule),
                canActivate: [ClientGuard]
            },
            {
                path: ':name_key/deals',
                loadChildren: () => import('./deals/deals.module').then(m => m.DealsModule),
                canActivate: [ClientGuard],
                data: {path: 'deals'}
            },
            {
                path: ':name_key/quote',
                loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule),
                canActivate: [ClientGuard]
            },
            {
                path: ':name_key/trip',
                loadChildren: () => import('./trip/trip.module').then(m => m.TripModule),
                canActivate: [ClientGuard]
            },
            {
                path: ':name_key/account',
                loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
                canActivate: [ClientGuard]
            },
            {
                path: ':name_key/:path',
                loadChildren: () => import(`@library/shared/flex/flex.page`).then(m => m.FlexPageModule),
                canActivate: [ClientGuard]
            },
            {
                path: '**',
                component: FlexPage,
                canActivate: [ClientGuard,LaroConsumerClientGuard],
                data: {header: true, footer: true}
            }
        ], {
            paramsInheritanceStrategy: 'always',
            scrollPositionRestoration: 'enabled'
        }),
        BrowserModule, HttpClientModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:5000'
        }),
        provideFirebaseApp(() => {
            return initializeApp(environment.firebase, environment.appName);
        }),
        provideAnalytics(() => {
            return getAnalytics(getApp(environment.appName));
        }),
        provideAuth(() => {
            return getAuth(getApp(environment.appName));
        }),
        AlertDialog, ProgressComponent, PopButtonComponent,
        ModalDialog, FormsModule, ReactiveFormsModule, NotificationDialog
    ],
    declarations: [
        ConsumerAppComponent
    ],
    providers: [
        ClientGuard, LaroConsumerClientGuard, ClientService, ThemeService, PageService, LocalStorageService,
        ConsumerFireService,AnalyticsService,ConsumerAccountService,CartService,
        {provide: 'ObjectLoaderService', useFactory: () => new LaroObjectLoaderService()},
        ScreenTrackingService, {provide: 'LazyComponents', useValue: [sharedLazyComponents, laroOperatorLazyComponents, laroConsumerLazyComponents]},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: 'MenuService', useValue: {getMenu: () => {}}}
    ],
    bootstrap: [ConsumerAppComponent]
})
export class ConsumerAppModule {
}
